// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

document.addEventListener('DOMContentLoaded', () => {


});
const swiperHero = new Swiper(".hero-swiper", {
  loop: true,
  slidesPerView: 1,
  spaceBetween: 30,
  speed: 1500,
  keyboard: {
    enabled: true,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".hero-swiper-button-next",
    prevEl: ".hero-swiper-button-prev",
  },
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
});

const swiperAbout = new Swiper(".about-swiper", {
  loop: true,
  slidesPerView: 1,
  speed: 2000,
  spaceBetween: 15,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination_about",
    clickable: true,
  },
});

const swiperPhoto = new Swiper(".photo-swiper", {
  loop: true,
  slidesPerView: 1.4,
  //spaceBetween: 20,
  speed: 2000,

  keyboard: {
    enabled: true,
  },

  navigation: {
    nextEl: ".swiper-button-next_photo",
    prevEl: ".swiper-button-prev_photo",
  },

});